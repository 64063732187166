import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate, ABanner } from 'shared/components/common'
import { Container, Row, Col } from 'reactstrap'

type PoliticasViajeProps = PageProps
interface PoliticasViajeState {
  mounted: boolean
}

class PoliticasViaje extends Component<PoliticasViajeProps, PoliticasViajeState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <ABanner title="Políticas de viaje" titleClassName="big-white" titleSize={12} />
          <Container className="pad">
            <Row>
              <Col sm={12} className="text-justify">
                <ul>
                  <li>
                    El boleto es válido únicamente para la fecha y hora indicada en el mismo, en caso de no abordar el autobús se cancelará
                    su boleto de manera automática sin responsabilidad para la empresa. Si el pasajero tuviera un retraso de hasta 60 min
                    posteriores a la salida del autobús designado para su viaje, tendrá la oportunidad de viajar en el siguiente autobús
                    otorgándole un 50% del valor del boleto, o bien tendrá la opción de transferir el 50% del valor de su boleto adquirido a
                    monedero electrónico el cual podrá aplicarlo en futuras compras.
                  </li>
                  <li>No se permitirá que ningún menor de 12 años viaje solo, deberá estar acompañado en todo momento de un adulto.</li>
                  <li>
                    El pasajero tendrá derecho a transportar 1 maleta con un peso de hasta 35 kg, o bien 2 maletas que en suma el peso no
                    sea mayor a 50 kg, por lo que, en caso de ser mayor en cualquiera de las dos situaciones, se cobrará un monto excedente.
                  </li>
                  <li>Para el caso de medidas, no se deberá exceder de .15 m3 o 160 cm lineales, de lo contrario se cobrará excedente.</li>
                  <li>
                    No se permitirá viajar a ningún pasajero en estado de ebriedad o bajo efectos de cualquier tipo de droga, o bien
                    pasajeros que muestren actitudes que puedan alterar el orden y poner en riesgo a los demás usuarios.
                  </li>
                  <li>
                    Si algún pasajero muestra actitudes que alteren el orden y seguridad de los usuarios en el trayecto del viaje, se le
                    solicitara su descenso en la próxima estación autorizada.
                  </li>
                  <li>
                    No se permitirá viajar con armas de fuego o armas punzo cortantes, drogas o cualquier otra mercancía prohibida por la
                    ley.
                  </li>
                  <li>Los boletos adquiridos con algún tipo de descuento no son transferibles.</li>
                  <li>
                    Cualquier controversia derivada de la prestación del servicio, las partes se sujetan a la normatividad en materia de
                    autotransporte federal de pasaje.
                  </li>
                  <li>El pasajero se deberá presentar en la puerta del andén correspondiente, 45 min antes de su salida.</li>
                  <li>
                    Antes de abordar deberán presentar identificación y el boleto que ampara su viaje en la hora y fecha señalada., en caso
                    de ser cliente con descuentos especiales (ESTUDIANTE, MAESTRO, INAPAM, PCD) deberán presentar su credencial vigente.
                  </li>
                  <li>
                    Para viajes internacionales se deberá presentar como mínimo 2 horas antes de la salida de su viaje y deberá presentar su
                    pasaporte y visa vigentes.
                  </li>
                  <li>
                    El pasajero es el único responsable de sus bienes durante los procesos de revisiones que realice cualquier autoridad,
                    así como también revisiones aduanales y líneas fronterizas.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </AAnimate>
      </Layout>
    )
  }
}

export default PoliticasViaje
